/**
 * Loading constants
*/
export const API_LOADING_START = 'API_LOADING_START';
export const API_LOADING_STOP = 'API_LOADING_STOP';

/**
 * Localstorage constants
*/
export const LOCALSTORAGEVALUE = 'LOCALSTORAGEVALUE';

/**
 * Make default icu constants
*/
export const MAKEDEFAULTICUOBJ = 'MAKEDEFAULTICUOBJ';

/**
 * Application Version request
 */
export const APPLICATION_VERSION_REQUEST_REQUEST = 'APPLICATION_VERSION_REQUEST_REQUEST';
export const APPLICATION_VERSION_REQUEST_SUCCESS = 'APPLICATION_VERSION_REQUEST_SUCCESS';
export const APPLICATION_VERSION_REQUEST_FAILURE = 'APPLICATION_VERSION_REQUEST_FAILURE';