/**
 * Update hospital info
*/
export const UPDATE_HOSPITAL_INFO_REQUEST = 'UPDATE_HOSPITAL_INFO_REQUEST';
export const UPDATE_HOSPITAL_INFO_SUCCESS = 'UPDATE_HOSPITAL_INFO_SUCCESS';
export const UPDATE_HOSPITAL_INFO_FAIL = 'UPDATE_HOSPITAL_INFO_FAIL';

/**
 * Create ICU
*/
export const CREATE_ICU_REQUEST = 'CREATE_ICU_REQUEST';
export const CREATE_ICU_SUCCESS = 'CREATE_ICU_SUCCESS';
export const CREATE_ICU_FAIL = 'CREATE_ICU_FAIL';

/**
 * Update hospital info
*/
export const UPDATE_ICU_BED_INFO_REQUEST = 'UPDATE_ICU_BED_INFO_REQUEST';
export const UPDATE_ICU_BED_INFO_SUCCESS = 'UPDATE_ICU_BED_INFO_SUCCESS';
export const UPDATE_ICU_BED_INFO_FAIL = 'UPDATE_HOSPITAL_INFO_FAIL';