import en from "Translation/en.json";
import i18n from 'i18next';


i18n.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
        en: {
            common: en               // 'common' is our custom namespace
        },
    },
});


export default i18n;