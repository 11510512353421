/**
 * SignalR event
*/
export const SIGNALR_EVENT_REQUEST = 'SIGNALR_EVENT_REQUEST';
export const SIGNALR_EVENT_SUCCESS = 'SIGNALR_EVENT_SUCCESS';
export const SIGNALR_EVENT_FAIL = 'SIGNALR_EVENT_FAIL';

/**
 * Get all wings
*/
export const ALL_WINGS_FETCH_REQUEST = 'ALL_WINGS_FETCH_REQUEST';
export const ALL_WINGS_FETCH_SUCCESS = 'ALL_WINGS_FETCH_SUCCESS';
export const ALL_WINGS_FETCH_FAIL = 'ALL_WINGS_FETCH_FAIL';

/**
 * Get all bed list
*/
export const BED_LIST_FETCH_REQUEST = 'BED_LIST_FETCH_REQUEST';
export const BED_LIST_FETCH_SUCCESS = 'BED_LIST_FETCH_SUCCESS';
export const BED_LIST_FETCH_FAIL = 'BED_LIST_FETCH_FAIL';

/**
 * Get patient weight list
*/
export const PATIENT_WEIGHT_LIST_FETCH_REQUEST = 'PATIENT_WEIGHT_LIST_FETCH_REQUEST';
export const PATIENT_WEIGHT_LIST_FETCH_SUCCESS = 'PATIENT_WEIGHT_LIST_FETCH_SUCCESS';
export const PATIENT_WEIGHT_LIST_FETCH_FAIL = 'PATIENT_WEIGHT_LIST_FETCH_FAIL';

/**
 * Get patient turn log list
*/
export const PATIENT_TURN_LOG_LIST_FETCH_REQUEST = 'PATIENT_TURN_LOG_LIST_FETCH_REQUEST';
export const PATIENT_TURN_LOG_LIST_FETCH_SUCCESS = 'PATIENT_TURN_LOG_LIST_FETCH_SUCCESS';
export const PATIENT_TURN_LOG_LIST_FETCH_FAIL = 'PATIENT_TURN_LOG_LIST_FETCH_FAIL';

/**
 * Post service request
*/
export const SERVICE_REQUEST_REQUEST = 'SERVICE_REQUEST_REQUEST';
export const SERVICE_REQUEST_SUCCESS = 'SERVICE_REQUEST_SUCCESS';
export const SERVICE_REQUEST_FAIL = 'SERVICE_REQUEST_FAIL';

/**
 * Patient id request
 */
export const PATIENT_ID_REQUEST_REQUEST = 'PATIENT_ID_REQUEST_REQUEST';
export const PATIENT_ID_REQUEST_SUCCESS = 'PATIENT_ID_REQUEST_SUCCESS';
export const PATIENT_ID_REQUEST_FAIL = 'PATIENT_ID_REQUEST_FAIL';